import React from "react";
import './footer.css'
import {InfoBox} from "../shared/info/info";

export const Footer = () => {
    return (
        <>
            <footer className="text-white-50 bg-dark text-center d-block d-md-none" id="contact">
                <div className="container-md pb-3 p-3">
                    <InfoBox/>
                    <div className="row p-3">
                        <div className="col-md-12 text-center text-secondary">
                            &copy; {(new Date().getFullYear())} Restaurant Masel Topf
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="impressumModal" tabIndex={-1} aria-labelledby="impressumModal"
                     aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Impressum</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body text-black text-start">
                                <p>
                                    Zimbri GmbH & Co. KG<br/>
                                    Gervinusstr. 24<br/>
                                    10629 Berlin<br/>
                                    Email: zimbrikg@hotmail.com<br/>
                                    Tel: +49 (0) 30 32799799<br/>
                                </p>
                                <p>Geschäftsführer Konstantin Pinski</p>
                                <p>
                                    Bankverbindung:<br/>
                                    Empfänger: Zimbri GmbH & Co. KG<br/>
                                    IBAN: DE67 1002 0890 0035 1296 34<br/>
                                    BIC: HYVEDEMM488<br/>
                                    Bank: Hypo Vereinsbank<br/>
                                </p>

                                <p>Steuernummer: 27/170/30519</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}