import React from "react";
import {useEffect} from 'react';
import logo from '../../logo.png';
import './mainmenu.css'
import {useTranslation} from 'react-i18next';
export const MainMenu = () => {

    const {t, i18n} = useTranslation();

    useEffect(() => {

        let widget = document.getElementById('quandoo-booking-widget')
        if (widget != null) {
            widget.innerHTML = ""
        }

        const script = document.createElement('script');
        script.src = "https://booking-widget.quandoo.com/index.js";
        script.setAttribute('data-merchant-id', '4326')
        script.setAttribute('data-theme', 'light')
        script.setAttribute('data-primary-color', 'f5b016')
        script.setAttribute('data-lang', i18n.language)
        script.async = false

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [i18n.language]);

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-light navbar-light fixed-top text-dark" id="top-navbar">
                <div
                    className="container-md d-flex flex-lg-row justify-content-lg-between flex-column justify-content-center">
                    <a className="navbar-brand" href="#">
                        <img src={logo} className="d-inline-block align-top" alt="logo"/>
                    </a>
                </div>
            </nav>
        </>
    );
};


